import React, { useState, useEffect } from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import firebase from 'firebase'
import moment from 'moment'
import { Adsense } from '@ctrl/react-adsense';

export default function Filial() {
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('d'))
    const [servicos] = useState({
        filiais: firebase.firestore().collection('filiais')
    })
    const [imagens, setImagens] = useState([])
    const [filial, setFilial] = useState({})

    useEffect(() => {
        servicos.filiais.doc(id).get().then((b) => {
            setFilial(b.data())
        })
        servicos.filiais.doc(id).collection('imagens').get().then((doc) => {
            const data = doc.docs.map((d) => d.data())
            setImagens(data.filter((d) => d.slug !== id))
        })
    }, [])
    return (
        <>
            <Header />
            <div className="relative z-10 overflow-hidden bg-primary pt-[120px] pb-[100px] md:pt-[130px] lg:pt-[160px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4">
                            <div className="text-center">
                                <h1 className="text-4xl font-semibold text-white">Filial {filial.id ? filial.nome : null}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {filial.id ?
                <section className="pt-20 pb-10 lg:pt-[120px] lg:pb-20">
                    <div className="container">
                        <div className="-mx-4 flex flex-wrap justify-center">
                            <div className="w-full px-4">
                                <div className="wow fadeInUp relative z-20 mb-[60px] h-[300px] overflow-hidden rounded md:h-[400px] lg:h-[500px]"
                                    data-wow-delay=".1s
          ">
                                    <img src={filial.avatar} alt="image"
                                        className="h-full w-full object-cover object-center" />
                                    <div
                                        className="absolute top-0 left-0 z-10 flex h-full w-full items-end bg-gradient-to-t from-dark-700 to-transparent">
                                        <div className="flex flex-wrap items-center p-4 pb-4 sm:p-8">
                                            <div className="mb-4 flex items-center">

                                                {/* <p className="mr-5 flex items-center text-sm font-medium text-white md:mr-8">
                                                    <span className="mr-3">
                                                        <svg width="18" height="13" viewBox="0 0 18 13" className="fillRule-current">
                                                            <path
                                                                d="M15.9754 0H2.02539C1.09727 0 0.337891 0.759375 0.337891 1.6875V10.6875C0.337891 11.3062 0.647266 11.8406 1.18164 12.15C1.43477 12.2906 1.74414 12.375 2.02539 12.375C2.30664 12.375 2.58789 12.2906 2.86914 12.15L5.34414 10.7156C5.45664 10.6594 5.56914 10.6312 5.68164 10.6312H15.9473C16.8754 10.6312 17.6348 9.87187 17.6348 8.94375V1.6875C17.6629 0.759375 16.9035 0 15.9754 0ZM16.6785 8.94375C16.6785 9.3375 16.3691 9.64687 15.9754 9.64687H5.70977C5.42852 9.64687 5.11914 9.73125 4.86602 9.87187L2.39102 11.3063C2.16602 11.4187 1.91289 11.4187 1.68789 11.3063C1.46289 11.1938 1.35039 10.9688 1.35039 10.7156V1.6875C1.35039 1.29375 1.65977 0.984375 2.05352 0.984375H16.0035C16.3973 0.984375 16.7066 1.29375 16.7066 1.6875V8.94375H16.6785Z" />
                                                            <path
                                                                d="M12.5721 3.375H5.03457C4.75332 3.375 4.52832 3.6 4.52832 3.88125C4.52832 4.1625 4.75332 4.3875 5.03457 4.3875H12.6002C12.8814 4.3875 13.1064 4.1625 13.1064 3.88125C13.1064 3.6 12.8533 3.375 12.5721 3.375Z" />
                                                            <path
                                                                d="M11.3908 6.55322H5.03457C4.75332 6.55322 4.52832 6.77822 4.52832 7.05947C4.52832 7.34072 4.75332 7.56572 5.03457 7.56572H11.4189C11.7002 7.56572 11.9252 7.34072 11.9252 7.05947C11.9252 6.77822 11.6721 6.55322 11.3908 6.55322Z" />
                                                        </svg>
                                                    </span>
                                                    05
                                                </p>
                                                <p className="flex items-center text-sm font-medium text-white">
                                                    <span className="mr-3">
                                                        <svg width="20" height="12" viewBox="0 0 20 12" className="fillRule-current">
                                                            <path
                                                                d="M10.2559 3.8125C9.03711 3.8125 8.06836 4.8125 8.06836 6C8.06836 7.1875 9.06836 8.1875 10.2559 8.1875C11.4434 8.1875 12.4434 7.1875 12.4434 6C12.4434 4.8125 11.4746 3.8125 10.2559 3.8125ZM10.2559 7.09375C9.66211 7.09375 9.16211 6.59375 9.16211 6C9.16211 5.40625 9.66211 4.90625 10.2559 4.90625C10.8496 4.90625 11.3496 5.40625 11.3496 6C11.3496 6.59375 10.8496 7.09375 10.2559 7.09375Z" />
                                                            <path
                                                                d="M19.7559 5.625C17.6934 2.375 14.1309 0.4375 10.2559 0.4375C6.38086 0.4375 2.81836 2.375 0.755859 5.625C0.630859 5.84375 0.630859 6.125 0.755859 6.34375C2.81836 9.59375 6.38086 11.5312 10.2559 11.5312C14.1309 11.5312 17.6934 9.59375 19.7559 6.34375C19.9121 6.125 19.9121 5.84375 19.7559 5.625ZM10.2559 10.4375C6.84961 10.4375 3.69336 8.78125 1.81836 5.96875C3.69336 3.1875 6.84961 1.53125 10.2559 1.53125C13.6621 1.53125 16.8184 3.1875 18.6934 5.96875C16.8184 8.78125 13.6621 10.4375 10.2559 10.4375Z" />
                                                        </svg>
                                                    </span>
                                                    05
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="-mx-4 flex flex-wrap">
                                    <div className="w-full px-4 lg:w-8/12">
                                        <div>
                                            <h2
                                                className="wow fadeInUp mb-6 text-[26px] font-bold leading-snug text-dark sm:text-3xl sm:leading-snug md:text-4xl md:leading-snug"
                                                data-wow-delay=".1s
                ">
                                                {filial.nome}
                                            </h2>
                                            {/*CONNTEUDO */}
                                            <p>{filial.sobre}</p>
                                            <h2
                                                style={{ marginTop: 30 }}
                                                className="wow fadeInUp mb-6 text-[26px] font-bold leading-snug text-dark sm:text-3xl sm:leading-snug md:text-4xl md:leading-snug"
                                                data-wow-delay=".1s
                ">
                                                Nos faça uma visita
                                            </h2>
                                            <iframe style={{ marginTop: 30 }} src={filial.mapa} width="100%" height="200" allowFullScreen="" loading="lazy"></iframe>

                                            <div style={{ marginTop: 30 }} className="-mx-4 mb-12 flex flex-wrap items-center">
                                                <div className="w-full px-4 md:w-1/2">
                                                    <div className="wow fadeInUp mb-8 flex flex-wrap items-center md:mb-0" data-wow-delay=".1s">
                                                        <p>Clicou, entregou!</p>
                                                    </div>
                                                </div>
                                                <div className="w-full px-4 md:w-1/2">
                                                    <div className="wow fadeInUp flex items-center md:justify-end" data-wow-delay=".1s">
                                                        <span className="mr-5 text-sm font-medium text-body-color">
                                                            Siga nossas redes
                                                        </span>
                                                        <div className="flex items-center">
                                                            <a href="https://www.facebook.com/entregajabr" className="mr-4 mb-2">
                                                                <svg width="32" height="32" viewBox="0 0 32 32" fillRule="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                                                                        fill="#4064AC" />
                                                                    <path
                                                                        d="M19.439 14.4H18.1992H17.7564V13.8839V12.2839V11.7677H18.1992H19.1291C19.3726 11.7677 19.5719 11.5613 19.5719 11.2516V8.51613C19.5719 8.23226 19.3947 8 19.1291 8H17.5128C15.7638 8 14.5461 9.44516 14.5461 11.5871V13.8323V14.3484H14.1033H12.5978C12.2878 14.3484 12 14.6323 12 15.0452V16.9032C12 17.2645 12.2435 17.6 12.5978 17.6H14.059H14.5018V18.1161V23.3032C14.5018 23.6645 14.7454 24 15.0996 24H17.1807C17.3136 24 17.4243 23.9226 17.5128 23.8194C17.6014 23.7161 17.6678 23.5355 17.6678 23.3806V18.1419V17.6258H18.1328H19.1291C19.4169 17.6258 19.6383 17.4194 19.6826 17.1097V17.0839V17.0581L19.9925 15.2774C20.0147 15.0968 19.9925 14.8903 19.8597 14.6839C19.8154 14.5548 19.6161 14.4258 19.439 14.4Z"
                                                                        fill="white" />
                                                                </svg>
                                                            </a>
                                                            {/* <a href="javascript:void(0)" className="mr-4 mb-2">
                                                                <svg width="32" height="33" viewBox="0 0 32 33" fillRule="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill="evenodd" clipRule="evenodd"
                                                                        d="M32 16.7347C32 25.5713 24.8366 32.7347 16 32.7347C7.16344 32.7347 0 25.5713 0 16.7347C0 7.89818 7.16344 0.734741 16 0.734741C24.8366 0.734741 32 7.89818 32 16.7347ZM23.6036 12.8349C24.3771 12.7431 25.1147 12.5375 25.8004 12.2334C25.2878 13.0004 24.6395 13.6738 23.8917 14.2128C23.8991 14.3765 23.9028 14.5417 23.9028 14.7074C23.9028 19.7617 20.0558 25.5892 13.0213 25.5892C10.8616 25.5892 8.85088 24.9563 7.15927 23.8708C7.45789 23.9064 7.76307 23.9244 8.07111 23.9244C9.8634 23.9244 11.5122 23.3132 12.8214 22.2873C11.1474 22.2562 9.73534 21.1504 9.24876 19.6313C9.48206 19.6758 9.72136 19.6995 9.96836 19.6995C10.3166 19.6995 10.6552 19.653 10.9757 19.5652C9.22651 19.2141 7.90768 17.6685 7.90768 15.8154C7.90768 15.7995 7.90768 15.7832 7.90796 15.767C8.42335 16.0542 9.01346 16.2262 9.64007 16.2458C8.61444 15.5602 7.93876 14.3891 7.93876 13.0625C7.93876 12.3618 8.12758 11.7044 8.45672 11.1396C10.3431 13.4541 13.1613 14.9766 16.3398 15.1361C16.2742 14.856 16.2402 14.5642 16.2402 14.2644C16.2402 12.1527 17.953 10.44 20.0647 10.44C21.1653 10.44 22.1593 10.9043 22.8569 11.6473C23.7277 11.4759 24.5466 11.1579 25.2856 10.7195C24.9995 11.6131 24.3934 12.3618 23.6036 12.8349Z"
                                                                        fill="#55ACEE" />
                                                                </svg>
                                                            </a> */}
                                                            <a href="https://www.linkedin.com/company/entregajabr/mycompany/" className="mb-2">
                                                                <svg width="33" height="32" viewBox="0 0 33 32" fillRule="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill="evenodd" clipRule="evenodd"
                                                                        d="M32.7861 16C32.7861 24.8366 25.6227 32 16.7861 32C7.94958 32 0.786133 24.8366 0.786133 16C0.786133 7.16344 7.94958 0 16.7861 0C25.6227 0 32.7861 7.16344 32.7861 16ZM8.50669 8.82376C8.50669 7.69545 9.36262 6.83666 10.6709 6.83666C11.9795 6.83666 12.7835 7.69545 12.8089 8.82376C12.8089 9.92811 11.9795 10.8117 10.6455 10.8117H10.6207C9.33781 10.8117 8.50669 9.92811 8.50669 8.82376ZM26.3457 23.884V17.2875C26.3457 13.7551 24.4593 12.1112 21.9431 12.1112C19.9109 12.1112 19.0045 13.2292 18.4963 14.0113V12.3813H14.6712C14.7226 13.4602 14.6712 23.8837 14.6712 23.8837H18.496V17.4595C18.496 17.1147 18.5219 16.7733 18.6226 16.5274C18.8998 15.8395 19.5276 15.129 20.5843 15.129C21.969 15.129 22.5212 16.1843 22.5212 17.7296V23.884H26.3457ZM18.4963 14.0113V14.0489H18.4709C18.4745 14.0424 18.4793 14.0358 18.4841 14.0292L18.4841 14.0291C18.4883 14.0232 18.4926 14.0173 18.4963 14.0113ZM8.73421 23.8839H12.5575V12.3812H8.73421V23.8839Z"
                                                                        fill="#007AB9" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full px-4 lg:w-4/12">
                                        <div>
                                            {/* <div
                                                className="wow fadeInUp relative mb-12 overflow-hidden rounded bg-primary py-[60px] px-11 text-center lg:px-8"
                                                data-wow-delay=".1s
                ">
                                                <h3 className="mb-2 text-2xl font-semibold text-white">
                                                    Fique por dentro!
                                                </h3>
                                                <p className="mb-8 text-base text-white">
                                                    Se inscreva em nossa newsletter e fique informado de tudo
                                                </p>
                                                <form>
                                                    <input type="email" placeholder="Seu melhor Email"
                                                        className="mb-4 h-[50px] w-full rounded border border-transparent bg-white bg-opacity-20 text-center text-sm font-medium text-white placeholder-white outline-none focus:border-white focus-visible:shadow-none" />
                                                    <input type="submit" value="Quero ficar por dentro"
                                                        className="mb-6 h-[50px] w-full cursor-pointer rounded bg-[#13C296] text-center text-sm font-medium text-white transition duration-300 ease-in-out hover:bg-opacity-90 hover:shadow-lg" />
                                                </form>
                                                <p className="text-sm font-medium text-white">
                                                    Não se preocupe, não mandamos spam
                                                </p>


                                            </div> */}

                                            {/* AD */}

                                            <div className="wow fadeInUp mb-12 overflow-hidden rounded" data-wow-delay=".1s">
                                                <Adsense
                                                    className='blockPostFilial'
                                                    client='ca-pub-9383816332258420'
                                                    slot='2494637879'
                                                    style={{ display: 'block', }}
                                                    layout="display"
                                                format="autorelaxed"
                                                    responsive={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {imagens.length > 0 ?
                            <div className="-mx-4 flex flex-wrap">
                                <div className="wow fadeInUp mt-14 w-full px-4" data-wow-delay=".2s">
                                    <h2 className="relative pb-5 text-2xl font-semibold text-dark sm:text-[28px]">
                                        Conheça a infraestrutura da Base
                                    </h2>
                                    <span className="mb-10 inline-block h-[2px] w-20 bg-primary"></span>
                                </div>
                                {imagens.map((b) =>
                                    <div key={b.id} className="w-full px-4 md:w-1/2 lg:w-1/3">
                                        <div className="wow fadeInUp group mb-10" data-wow-delay=".1s">
                                            <div className="mb-8 overflow-hidden rounded">
                                                <a href="#" className="block">
                                                    <img src={b.avatar} alt="image"
                                                        className="w-full transition group-hover:rotate-6 group-hover:scale-125" />
                                                </a>
                                            </div>
                                            <div>
                                                <span className="mb-5 inline-block rounded bg-primary py-1 px-4 text-center text-xs font-semibold leading-loose text-white">
                                                    {moment(b.dataCadastro).format('ll')}
                                                </span>
                                                <h3>
                                                    <a href="#"
                                                        className="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary sm:text-2xl lg:text-xl xl:text-2xl">
                                                        {b.titulo}
                                                    </a>
                                                </h3>
                                                {/* <p className="text-base text-body-color">
                                                    {b.descricao}
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            :
                            null
                        }

                    </div>
                </section>
                :
                null
            }


            <Footer />
        </>
    );
}
