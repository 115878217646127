import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Adsense } from '@ctrl/react-adsense';

export default function Parceios() {
    return (
        <>
            <Header />
            <div className="relative z-10 overflow-hidden bg-primary pt-[120px] pb-[100px] md:pt-[130px] lg:pt-[160px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4">
                            <div className="text-center">
                                <h1 className="text-4xl font-semibold text-white">Venha Fazer  Parte da Revolução Logística com a Entrega Já!</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <Adsense
                    className='blockParceiros'
                    client='ca-pub-9383816332258420'
                    slot='8682041194'
                    style={{ display: 'block' }}
                    layout="in-article"
                    format="fluid"
                    responsive={true}
                />
            </div>
            <section className="pt-20 pb-10 lg:pt-[120px] lg:pb-20">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap">
                        <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                            <div class="wow fadeInUp group mb-10" data-wow-delay=".1s">
                                <div class="mb-8 overflow-hidden rounded">
                                    <a href="https://entrega-ja-a942a.web.app/" class="block">
                                        <img src="assets/images/agregados.jpeg" alt="image"
                                            class="w-full transition group-hover:rotate-6 group-hover:scale-125" />
                                    </a>
                                </div>
                                <div>
                                    <span
                                        class="mb-5 inline-block rounded bg-primary py-1 px-4 text-center text-xs font-semibold leading-loose text-white">
                                        Tempo Indeterminado
                                    </span>
                                    <h3>
                                        <a href="https://agregados.entregaja.com.br/"
                                            class="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary sm:text-2xl lg:text-xl xl:text-2xl">
                                            Seja parceiro Agregado
                                        </a>
                                    </h3>
                                    <p class="text-base text-body-color">
                                        Cadasre seu utilitário, caminhão ou carreta na Entrega Já e comece a carregar na Entrega Já.
                                    </p>
                                    <p class="text-base text-body-color" >
                                        <b>Observação: Apenas veículos com ANTT.</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                            <div class="wow fadeInUp group mb-10" data-wow-delay=".1s">
                                <div class="mb-8 overflow-hidden rounded">
                                    <a href="blog-details.html" class="block">
                                        <img src="assets/images/blog/blog-01.jpg" alt="image"
                                            class="w-full transition group-hover:rotate-6 group-hover:scale-125" />
                                    </a>
                                </div>
                                <div>
                                    <span
                                        class="mb-5 inline-block rounded bg-primary py-1 px-4 text-center text-xs font-semibold leading-loose text-white">
                                        Tempo Indeterminado
                                    </span>
                                    <h3>
                                        <a href="blog-details.html"
                                            class="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary sm:text-2xl lg:text-xl xl:text-2xl">
                                            Seja um Agente
                                        </a>
                                    </h3>
                                    <p class="text-base text-body-color">
                                        Execute serviços junto com a gente em diversas cidades do Brasil
                                    </p>
                                </div>
                            </div>
                        </div> */}
                        <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                            <div class="wow fadeInUp group mb-10" data-wow-delay=".1s">
                                <div class="mb-8 overflow-hidden rounded">
                                    <a href="/oportunidades" class="block">
                                        <img src="assets/images/trabalheconosco.jpeg" alt="image"
                                            class="w-full transition group-hover:rotate-6 group-hover:scale-125" />
                                    </a>
                                </div>
                                <div>
                                    <span
                                        class="mb-5 inline-block rounded bg-primary py-1 px-4 text-center text-xs font-semibold leading-loose text-white">
                                        Tempo Indeterminado
                                    </span>
                                    <h3>
                                        <a href="/oportunidades"
                                            class="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary sm:text-2xl lg:text-xl xl:text-2xl">
                                            Faça parte do nosso Time
                                        </a>
                                    </h3>
                                    <p class="text-base text-body-color">
                                    Faça parte da nossa equipe! Cadastre-se e, assim que surgir uma oportunidade, entraremos em contato com você.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    );
}
