import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import firebase from 'firebase'
import moment from 'moment'

export default function Contato() {
    const [servicos] = useState({
        funil: firebase.firestore().collection('funil'),
        mail: firebase.firestore().collection('mail'),
    })
    // Contato
    const [empresa, setEmpresa] = useState('')
    const [representante, setRepresentante] = useState('')
    const [email, setEmail] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [mensagem, setMensagem] = useState('')
    const [erroForm, setErroForm] = useState(0)
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [sucessoEmail, setSucessoEmail] = useState(false);

    function enviaEmail() {
        setErroForm(0)
        if (!empresa) {
            setErroForm(1)
            return
        }
        if (!representante) {
            setErroForm(2)
            return
        }
        if (!email) {
            setErroForm(3)
            return
        }
        if (!whatsapp && whatsapp.length < 11) {
            setErroForm(4)
            return
        }
        if (!mensagem) {
            setErroForm(5)
            return
        }
        setLoadingEmail(true)
        servicos.funil.add({
            empresa: empresa,
            representante: representante,
            email: email,
            whatsapp: whatsapp,
            mensagem: mensagem,
            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
            meio: 'site',
            status: parseInt(0)
        }).then((snap) => {
            servicos.funil.doc(snap.id).update({
                id: snap.id
            }).then(() => {
                servicos.mail.add({
                    to: 'comercial@entregaja.com.br',
                    from: email,
                    message: {
                        subject: 'Solicitação de Reunião',
                        html: "<b>Nome da Empresa:</b> " + empresa + "<br />" + "<b>Nome do Representante:</b> " + representante + "<br />" + "<b>Email do represante: </b>" + email + "<br />" + "<b>Whatsapp do represante: </b>" + whatsapp + "<br />" + "<b>Mensagem : </b><br />" + mensagem,
                    },
                }).then(() => {
                    setLoadingEmail(false)
                    setSucessoEmail(true)
                })
            })
        })
    }

    // Form
    const handleEmpresa = (event) => {
        event.persist();
        setEmpresa(event.target.value);
    }
    const handleRepresentante = (event) => {
        event.persist();
        setRepresentante(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleWhatsapp = (event) => {
        event.persist();
        setWhatsapp(event.target.value);
    }
    const handleMensagem = (event) => {
        event.persist();
        setMensagem(event.target.value);
    }
    return (
        <>
            <Header />
            <div className="relative z-10 overflow-hidden bg-primary pt-[120px] pb-[100px] md:pt-[130px] lg:pt-[160px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4">
                            <div className="text-center">
                                <h1 className="text-4xl font-semibold text-white">Fale com nosso comercial</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="contato" className="relative py-20 md:py-[120px]">
                <div className="absolute top-0 left-0 z-[-1] h-1/2 w-full bg-[#f3f4fe] lg:h-[45%] xl:h-1/2"></div>
                <div className="container px-4">
                    <div className="-mx-4 flex flex-wrap items-center">

                        <div className="w-full px-4 lg:w-12/12 xl:w-12/12">
                            <div
                                className="wow fadeInUp rounded-lg bg-white py-10 px-8 shadow-testimonial sm:py-12 sm:px-10 md:p-[60px] lg:p-10 lg:py-12 lg:px-10 2xl:p-[60px]"
                                data-wow-delay=".2s
              ">
                                <h3 className="mb-8 text-2xl font-semibold md:text-[26px]">
                                    Que tal marcar uma Reunião?
                                </h3>
                                <div style={{ padding: 20, alignItems: 'center' }}>
                                    <iframe src="https://app.leadster.com.br/capture/cVSA2v1yY4odGpMr" width="100%" height="500">
                                        <p>Your browser does not support iframes.</p>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}
