import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import firebase from 'firebase'
import { Adsense } from '@ctrl/react-adsense';

export default function Sobre() {
    const [servicos] = useState({
        filiais: firebase.firestore().collection('filiais').where('status', '==', 'ATIVO')
    })
    const [filiais, setFiliais] = useState([])

    useEffect(() => {
        servicos.filiais.get().then((doc) => {
            const data = doc.docs.map((d) => d.data())
            setFiliais(data)
        })
    }, [servicos.filiais])
    return (
        <>
            <Header />
            <div className="relative z-10 overflow-hidden bg-primary pt-[120px] pb-[100px] md:pt-[130px] lg:pt-[160px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4">
                            <div className="text-center">
                                <h1 className="text-4xl font-semibold text-white">Uma história de comprometimento, excelência e qualidade</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <Adsense
                    className='blockSobre'
                    client='ca-pub-9383816332258420'
                    slot='8682041194'
                    style={{ display: 'block' }}
                    layout="in-article"
                    format="fluid"
                    responsive={true}
                />
            </div>
            <section id="about" className="bg-[#f3f4fe] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]">
                <div className="container">

                    <div className="wow fadeInUp bg-white" data-wow-delay=".2s">
                        <div className="-mx-4 flex flex-wrap">
                            <div className="w-full px-4">
                                <div className="items-center justify-between overflow-hidden border lg:flex">
                                    <div
                                        className="w-full py-12 px-7 sm:px-12 md:p-16 lg:max-w-[565px] lg:py-9 lg:px-16 xl:max-w-[640px] xl:p-[70px]">
                                        <span className="mb-5 inline-block bg-primary py-2 px-5 text-sm font-medium text-white">
                                            Quem Somos?
                                        </span>
                                        <h2 className="mb-6 text-3xl font-bold text-dark sm:text-4xl sm:leading-snug 2xl:text-[40px]">
                                            Entrega Já. Clicou, entregou!
                                        </h2>
                                        <p style={{ textAlign: "justify" }} className="mb-9 text-base leading-relaxed text-body-color">
                                        Fundada em 2017, a Entrega Já tem como missão elevar a experiência do cliente através de serviços logísticos de excelência. Nosso foco está na transparência de informações e no atendimento personalizado. Nossa equipe, composta por profissionais altamente qualificados, oferece estratégias que agregam valor, utilizando tecnologia e eficiência para atender rapidamente e com qualidade a todas as suas necessidades.
                                        </p>
                                        <p style={{ textAlign: "justify" }} className="mb-9 text-base leading-relaxed text-body-color">
                                        Nossa sede está localizada em São Luís/MA, e temos filiais em cidades-chave como Imperatriz/MA, Marabá/PA, Teresina/PI e Guarulhos/SP. Com uma extensa rede logística, especialmente forte nas regiões Norte, Nordeste e Sudeste do Brasil, estendemos nossos serviços por todo o território nacional. Contamos com pontos de apoio em diversas cidades para oferecer soluções personalizadas que unem qualidade, segurança e excelente custo-benefício.
                                        </p>
                                        <p style={{ textAlign: "justify" }} className="mb-9 text-base leading-relaxed text-body-color">
                                        A segurança e o conforto de nossos clientes são primordiais. Por isso, dispomos de uma frota própria, moderna e adaptável às necessidades individuais de cada cliente. Além disso, todos os nossos veículos são rastreados 24/7, assegurando maior tranquilidade e segurança durante todo o processo logístico
                                        </p>
                                    </div>
                                    <div >
                                        <div className="relative z-10 inline-block">
                                            <img src="assets/images/mapa.jpg" alt="image" className="mx-auto lg:ml-auto" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative z-20 overflow-hidden bg-[#f3f4ff] pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4">
                            <div className="mx-auto mb-[60px] max-w-[620px] text-center lg:mb-20">
                                <span className="mb-2 block text-lg font-semibold text-primary">
                                    Nossa Casa
                                </span>
                                <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[42px]">
                                    Filiais que fazem a difefrença
                                </h2>
                                <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                                    Com mais de 4 filiais , estamos sempre prontos para atendê-los
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="-mx-4 flex flex-wrap">
                        {filiais.map((b) =>
                            <div key={b.id} className="w-full px-4 md:w-1/2 lg:w-1/3">
                                <div className="wow fadeInUp group mb-10" data-wow-delay=".1s">
                                    <div className="mb-8 overflow-hidden rounded">
                                        <a href={"/filial?d=" + b.id} className="block">
                                            <img src={b.avatar} alt="image"
                                                className="w-full transition group-hover:rotate-6 group-hover:scale-125" />
                                        </a>
                                    </div>
                                    <div>
                                        <span className="mb-5 inline-block rounded bg-primary py-1 px-4 text-center text-xs font-semibold leading-loose text-white">
                                            {b.endereco.uf}
                                        </span>
                                        <h3>
                                            <a href={"/filial?d=" + b.id}
                                                className="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary sm:text-2xl lg:text-xl xl:text-2xl">
                                                {b.nome}
                                            </a>
                                        </h3>
                                        <p className="text-base text-body-color">
                                            {b.endereco.endereco}, {b.endereco.bairro}<br /> {b.endereco.cep} - {b.endereco.cidade}-{b.endereco.uf}<br />
                                            {/* {b.endereco.complemento} */}
                                        </p>
                                        <p style={{ fontSize: 12, marginTop: 10 }}>Clique e conheça nossa filial</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="absolute bottom-0 right-0 z-[-1]">
                    <svg width="1440" height="886" viewBox="0 0 1440 886" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5"
                            d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
                            fill="url(#paint0_linear)" />
                        <defs>
                            <linearGradient id="paint0_linear" x1="1308.65" y1="1142.58" x2="602.827" y2="-418.681"
                                gradientUnits="userSpaceOnUse">
                                <stop stopColor="#3056D3" stopOpacity="0.36" />
                                <stop offset="1" stopColor="#F5F2FD" stopOpacity="0" />
                                <stop offset="1" stopColor="#F5F2FD" stopOpacity="0.096144" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </section>

            <Footer />
        </>
    );
}
