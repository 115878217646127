export const consultaDestinatario = (dados) => {
    return (async () => {
        try {
            const URL = "https://us-central1-entrega-ja-a942a.cloudfunctions.net/app/consultaPedido";
            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            const response = await fetch(URL, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(dados)
            })
            const data = await response.json()
            return data
        } catch (error) {
            return 3
        }
    })();
}