import React, { useState, useEffect } from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import firebase from 'firebase'
import moment from 'moment'
import { Adsense } from '@ctrl/react-adsense';

export default function PageLista() {
    const [servicos] = useState({
        blog: firebase.firestore().collection('blog')
    })
    const [blog, setBlog] = useState([])

    useEffect(() => {
        servicos.blog.where('status', '==', 'ATIVO').get().then((doc) => {
            const data = doc.docs.map((d) => d.data())
            setBlog(data.sort((a, b) => {
                return moment(b.dataAtualizacao) - moment(a.dataAtualizacao);
            }))
        })
    }, [])
    return (
        <>
            <Header />
            <div className="relative z-10 overflow-hidden bg-primary pt-[120px] pb-[100px] md:pt-[130px] lg:pt-[160px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4">
                            <div className="text-center">
                                <h1 className="text-4xl font-semibold text-white">Acompanhe as novidades nos conteúdos do nosso blog.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <Adsense
                    className='blockBlog'
                    client='ca-pub-9383816332258420'
                    slot='8682041194'
                    style={{ display: 'block' }}
                    layout="in-article"
                    format="fluid"
                    responsive={true}
                />
            </div>
            <section className="pt-20 pb-10 lg:pt-[120px] lg:pb-20">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap">
                        {blog.map((b) =>
                            <div key={b.id} className="w-full px-4 md:w-1/2 lg:w-1/3">
                                <div className="wow fadeInUp group mb-10" data-wow-delay=".1s">
                                    <div style={{ height: 260 }} className="mb-8 overflow-hidden rounded">
                                        <a href={"/post?d=" + b.slug} className="block">
                                            <img src={b.miniatura} alt="image"
                                                className="w-full transition group-hover:rotate-6 group-hover:scale-125" />
                                        </a>
                                    </div>
                                    <div>
                                        <span className="mb-5 inline-block rounded bg-primary py-1 px-4 text-center text-xs font-semibold leading-loose text-white">
                                            {moment(b.dataCadastro).format('ll')}
                                        </span>
                                        <h3 style={{ height: 75 }}>
                                            <a href={"/post?d=" + b.slug}
                                                className="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary sm:text-2xl lg:text-xl xl:text-2xl">
                                                {b.titulo.substr(0, 50)}...
                                            </a>
                                        </h3>
                                        <p className="text-base text-body-color">
                                            {b.descricao.substr(0, 90)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            {/*  */}


            <Footer />
        </>
    );
}
