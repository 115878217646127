import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { consultaDestinatario } from '../../services/ssw';
import firebase from 'firebase'
import moment from 'moment'
import { Chrono } from "react-chrono";


import { Adsense } from '@ctrl/react-adsense';
import Modal from 'react-modal';

// import BlocoRodape from '../../components/Ads/BlocoRodape'
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default function Home() {
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [close, setClose] = useState(false);
    const [atendente, setAtendente] = useState({});
    const [tamanho, setTamanho] = useState(window.screen.width)

    const [usuarios] = useState([
        { id: 1, atendente: 'Joan Rubim', img: 'https://static.wixstatic.com/media/bec156_c47ed410a906455cb20d4acd6959b7f2~mv2.png/v1/fit/w_940%2Ch_534%2Cal_c/file.png', contato: '98983131655' },
        { id: 2, atendente: 'Guilherme Rian', img: 'https://suporte.makito.com.br/img/offline.jpg', contato: '98983131655' },
        { id: 2, atendente: 'Andreia ', img: 'https://suporte.makito.com.br/img/offline.jpg', contato: '98983131655' },
        { id: 2, atendente: 'Fabiano', img: 'https://suporte.makito.com.br/img/offline.jpg', contato: '98983131655' },
    ])

    const [servicos] = useState({
        blog: firebase.firestore().collection('blog').where('status', '==', 'ATIVO'),
        funil: firebase.firestore().collection('funil'),
        mail: firebase.firestore().collection('mail'),
        monitoramento: firebase.firestore().collection('monitoramento'),
    })
    const [tipoBusca, setTipoBusca] = useState('')
    const [cpfCnpj, setCpfCnpj] = useState('')
    const [minuta, setMinuta] = useState('')
    const [erro, setErro] = useState('');
    const [loading, setLoading] = useState(false);
    const [corpoMinuta, setCorpoMinuta] = useState({});

    const [events, setEvents] = useState([])
    const [blog, setBlog] = useState([])

    // Contato
    const [empresa, setEmpresa] = useState('')
    const [representante, setRepresentante] = useState('')
    const [email, setEmail] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [mensagem, setMensagem] = useState('')
    const [erroForm, setErroForm] = useState(0)
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [sucessoEmail, setSucessoEmail] = useState(false);

    useEffect(() => {
        servicos.blog.get().then((doc) => {
            const data = doc.docs.map((d) => d.data())
            setBlog(data.sort((a, b) => {
                return moment(b.dataAtualizacao) - moment(a.dataAtualizacao);
            }))
        })
    }, [servicos.blog])

    function closeModal() {
        setIsOpen(false);
        setClose(true)
    }

    function limpaBusca() {
        setErroForm(0)
        setErro('')
        setEvents([])
        setMinuta('')
        setCpfCnpj('')
        setTipoBusca(null)
    }

    function buscaPedido() {
        if (cpfCnpj.length < 14) {
            setErroForm(1)
            return
        }
        if (minuta < 6) {
            setErroForm(2)
            return
        }
        setLoading(true)
        const corpo = {
            cnpj: cpfCnpj,
            nro_nf: minuta,
            sigla_emp:"ETJ"
        }
        consultaDestinatario(corpo).then((res) => {
            if (res.success) {
                setLoading(false)
                const list = []
                setCorpoMinuta({
                    destinatario: removeFromParenthesis(res.header.destinatario)
                })
                res.tracking.forEach((d) => {
                    list.push({
                        title: moment(d.data_hora).format('DD/MM/YYYY'),
                        cardTitle: removeFromParenthesis(d.ocorrencia) + ' - ' + moment(d.data_hora).format('DD/MM/YYYY - HH:mm'),
                        url: "http://www.history.com",
                        cardSubtitle: d.cidade,
                        cardDetailedText: d.descricao,
                        date_filter: d.data_hora,
                        // date: moment(d.data_hora).format('DD/MM')
                    })
                })
                setEvents(list.sort((a, b) => {
                    return moment(b.date_filter) - moment(a.date_filter);
                }))
            } else {
                setLoading(false)
                setErro(res.message)
            }
        })
    }

    function removeFromParenthesis(text) {
        const index = text.indexOf('(');
        if (index !== -1) {
            return text.slice(0, index).trim();
        }
        return text;
    }

    function enviaEmail() {
        setErroForm(0)
        if (!empresa) {
            setErroForm(1)
            return
        }
        if (!representante) {
            setErroForm(2)
            return
        }
        if (!email) {
            setErroForm(3)
            return
        }
        if (!whatsapp && whatsapp.length < 11) {
            setErroForm(4)
            return
        }
        if (!mensagem) {
            setErroForm(5)
            return
        }
        setLoadingEmail(true)
        servicos.funil.add({
            empresa: empresa,
            representante: representante,
            email: email,
            whatsapp: whatsapp,
            mensagem: mensagem,
            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
            meio: 'site',
            status: parseInt(0)
        }).then((snap) => {
            servicos.funil.doc(snap.id).update({
                id: snap.id
            }).then(() => {
                servicos.mail.add({
                    to: 'comercial@entregaja.com.br',
                    from: email,
                    message: {
                        subject: 'Solicitação de Reunião',
                        html: "<b>Nome da Empresa:</b> " + empresa + "<br />" + "<b>Nome do Representante:</b> " + representante + "<br />" + "<b>Email do represante: </b>" + email + "<br />" + "<b>Whatsapp do represante: </b>" + whatsapp + "<br />" + "<b>Mensagem : </b><br />" + mensagem,
                    },
                }).then(() => {
                    setLoadingEmail(false)
                    setSucessoEmail(true)
                })
            })
        })
    }

    function cotacao() {
        servicos.monitoramento.doc('cotacao').get().then((res) => {
            servicos.monitoramento.doc('cotacao').update({
                site: parseInt(res.data().site) + parseInt(1),
            }).then(() => {
                window.location = 'https://cotacao.entregaja.com.br'
            })
        })
    }

    const handleCpfCnpj = (event) => {
        event.persist();
        setCpfCnpj(event.target.value);
    }
    const handleMinuta = (event) => {
        event.persist();
        setMinuta(event.target.value);
    }

    // Form
    const handleEmpresa = (event) => {
        event.persist();
        setEmpresa(event.target.value);
    }
    const handleRepresentante = (event) => {
        event.persist();
        setRepresentante(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleWhatsapp = (event) => {
        event.persist();
        setWhatsapp(event.target.value);
    }
    const handleMensagem = (event) => {
        event.persist();
        setMensagem(event.target.value);
    }

    return (
        <>

            <Header />
            <div id="home" className="relative overflow-hidden bg-primary ">
                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                >
                    <div>
                        <img alt="slide1" src="assets/images/slide/1.png" />
                    </div>
                    <div>
                        <img alt="slide2" src="assets/images/slide/2.png" />
                    </div>
                    <div>
                        <img alt="slide3" src="assets/images/slide/3.png" />
                    </div>
                </Carousel>
            </div>
            {/* <BlocoRodape /> */}
            {/* <div style={{ textAlign: 'center' }}>
                <Adsense
                    className='blockHome1'
                    client='ca-pub-9383816332258420'
                    slot='8682041194'
                    style={{ display: 'block' }}
                    layout="in-article"
                    format="fluid"
                    responsive={true}
                />
            </div> */}
            <div style={{ textAlign: 'center' }} >
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={true}
                >
                    <div style={{ textAlign: 'end' }}>
                        <button style={{ fontSize: 20 }} onClick={() => setIsOpen(false)} type="button">
                            x
                        </button>
                    </div>
                    <img onClick={() => cotacao()} style={{ color: "#fffff", width: '100%', height: tamanho >= 1000 ? '400px' : '100%' }} src="/assets/images/cotacao.png" />
                    {/* <div style={{ padding: 10, textAlign: 'center' }}>
                        <button onClick={() => setIsOpen(false)} type="button"
                            className="inline-flex items-center justify-center rounded bg-primary py-2 px-2 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-dark">
                            Fazer cotação depois
                        </button>
                    </div> */}

                </Modal>
            </div>
            <section id="servicos" className="pt-20 pb-8 lg:pt-[120px] lg:pb-[70px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4">
                            <div className="mb-12 max-w-[620px] lg:mb-20">
                                <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[42px]">
                                    Entrega Já! clicou, entregou.
                                </h2>
                                <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed" >
                                    Soluções Logísticas Personalizadas e Escalonáveis para Qualquer Tamanho de Negócio
                                    Nossa especialidade engloba transporte, logística e distribuição, atendendo B2B, B2C. Com um atendimento personalizado.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                            <div className="wow fadeInUp group mb-12 bg-white" data-wow-delay=".1s">
                                <div className="relative z-10 mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                                    <span
                                        className="absolute top-0 left-0 z-[-1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"></span>
                                    <img style={{ color: "#fffff", width: 50, height: 50 }} src="assets/images/icons/2.svg" />
                                </div>
                                <h4 className="mb-3 text-xl font-bold text-dark">
                                    Entrega Expressa
                                </h4>
                                <p className="mb-8 text-body-color lg:mb-11">
                                    Entregas rápidas e prioritárias que atendem suas demandas mais urgentes para vários destinos do país.
                                </p>
                            </div>
                        </div>
                        <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                            <div className="wow fadeInUp group mb-12 bg-white" data-wow-delay=".15s">
                                <div className="relative z-10 mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                                    <span
                                        className="absolute top-0 left-0 z-[-1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"></span>
                                    <img style={{ color: "#fffff", width: 50, height: 50 }} src="assets/images/icons/3.svg" />
                                </div>
                                <h4 className="mb-3 text-xl font-bold text-dark">
                                    Carga Lotação
                                </h4>
                                <p className="mb-8 text-body-color lg:mb-11">
                                    Foco em operações de cargas maiores, com mercadorias que ocupam toda a capacidade do veículo.
                                </p>
                            </div>
                        </div>
                        <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                            <div className="wow fadeInUp group mb-12 bg-white" data-wow-delay=".2s">
                                <div className="relative z-10 mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                                    <span
                                        className="absolute top-0 left-0 z-[-1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"></span>
                                    <img style={{ color: "#fffff", width: 50, height: 50 }} src="assets/images/icons/1.svg" />
                                </div>
                                <h4 className="mb-3 text-xl font-bold text-dark">
                                    Cargas Fracionadas
                                </h4>
                                <p className="mb-8 text-body-color lg:mb-11">
                                    Ideal para o envio de pequenas cargas, agrupando diversos tipos de mercadorias em um único veículo de transporte.
                                </p>
                            </div>
                        </div>
                        <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                            <div className="wow fadeInUp group mb-12 bg-white" data-wow-delay=".25s">
                                <div className="relative z-10 mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                                    <span
                                        className="absolute top-0 left-0 z-[-1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"></span>
                                    <img style={{ color: "#fffff", width: 50, height: 50 }} src="assets/images/icons/4.svg" />
                                </div>
                                <h4 className="mb-3 text-xl font-bold text-dark">
                                    Armazenagem
                                </h4>
                                <p className="mb-8 text-body-color lg:mb-11">
                                    Infraestrutura completa para acondicionamento e distribuição com inteligência de armazenagem.

                                </p>
                            </div>
                        </div>
                        {/* <a style={{ width: '100%', backgroundColor: 'green',textAlign:'center' }} href="https://wa.me/551134750544?text=Olá vim através do site da Entrega Já. Gostaria de conhecer mais sobre os serviços"
                            className="inline-flex items-center justify-center rounded bg-primary py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-opacity-90 hover:shadow-lg">
                            <i style={{ marginRight: 10, fontSize: 20 }} class="fa fa-whatsapp"></i>  Entre em contato conosco pelo WhatsApp
                        </a> */}
                    </div>
                </div>
            </section>
            <section id="sobre" className="bg-[#f3f4fe] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]">
                <div className="container">
                    <div className="wow fadeInUp bg-white" data-wow-delay=".2s">
                        <div className="-mx-4 flex flex-wrap">
                            <div className="w-full px-4">
                                <div className="items-center justify-between overflow-hidden border lg:flex">
                                    <div
                                        className="w-full py-12 px-7 sm:px-12 md:p-16 lg:max-w-[565px] lg:py-9 lg:px-16 xl:max-w-[640px] xl:p-[70px]">

                                        <h2 className="mb-6 text-3xl font-bold text-dark sm:text-4xl sm:leading-snug 2xl:text-[40px]">
                                            Clique e Descubra Todas as Soluções que Oferecemos para Sua Empresa!
                                        </h2>
                                        <a href="/servicos"
                                            className="inline-flex items-center justify-center rounded bg-primary py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-opacity-90 hover:shadow-lg">
                                            Saiba mais sobre nós
                                        </a>

                                    </div>
                                    <div className="text-center">
                                        <div className="relative z-10 inline-block">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/Pu-T48lQ8as" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                {/* <div style={{textAlign:'center'}}>
                                    <a style={{ width: '98%', backgroundColor: 'green', marginBottom: 20 }} href="https://wa.me/551134750544?text=Olá vim através do site da Entrega Já. Gostaria de conhecer mais sobre os serviços"
                                        className="inline-flex items-center justify-center rounded bg-primary py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-opacity-90 hover:shadow-lg">
                                        <i style={{ marginRight: 10, fontSize: 20 }} class="fa fa-whatsapp"></i>  Entre em contato conosco pelo WhatsApp
                                    </a>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="rastreio" className="relative z-20 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]">
                <div className="container">
                    <div className="wow fadeInUp bg-white" data-wow-delay=".2s">
                        <div className="-mx-4 flex flex-wrap">
                            <div className="w-full px-4">
                                <div className="mx-auto mb-[60px] max-w-[620px] text-center lg:mb-20">
                                    <span className="mb-2 block text-lg font-semibold text-primary">
                                        Rastreie sua encomenda agora mesmo!
                                    </span>
                                    <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[42px]">
                                        Acompanhe o status de entrega
                                    </h2>
                                    <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                                        Utilize esta área para localizar a sua encomenda e obter detalhes sobre a entrega em poucos segundos.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="-mx-4 flex flex-wrap">
                            <div className="w-full px-4 lg:w-1/2">
                                <img src="assets/images/cargaja.jpeg" alt="image" className="mx-auto lg:ml-auto" />
                            </div>
                            <div className="w-full px-4 lg:w-1/2">
                                {loading ?
                                    <>
                                        <h2 style={{ textAlign: "center" }} className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[42px]">
                                            Buscando sua encomenda...
                                        </h2>
                                        <img src="assets/images/loading.gif" alt="image" className="mx-auto lg:ml-auto" />
                                    </>
                                    :
                                    <>
                                        {events.length > 0 ?
                                            <>

                                                <h5 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[20px]">
                                                    Olá, {corpoMinuta.destinatario}
                                                </h5>
                                                <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                                                    Consulta do documento <b>{minuta}</b>
                                                </p>
                                                {/* <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                                                    Previsão para {corpoMinuta.prev_entrega && corpoMinuta.prev_entrega.includes('-') ? moment(corpoMinuta.prev_entrega).format('DD/MM/YYYY - HH:mm') : corpoMinuta.prev_entrega ? corpoMinuta.prev_entrega : 'Aguardando Previsão'}
                                                </p><br /> */}
                                                {/* <Timeline style={{ marginTop: 20 }} items={events} /><br /><br />
                                                 */}
                                                <div style={{ width: "100%", height: "500px" }}>
                                                    <Chrono items={events} mode="VERTICAL" />
                                                </div>
                                                <button style={{ marginTop: 20, width: '100%' }} onClick={() => limpaBusca()} type="button"
                                                    className="inline-flex items-center justify-center rounded bg-primary py-4 px-2 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-dark">
                                                    Novo Rastreio
                                                </button>
                                            </>
                                            : erro ?
                                                <>
                                                    <h3 style={{ textAlign: "center" }} className="mb-4 text-3xl font-bold text-dark sm:text-4xl ">
                                                        Documento não encontrado
                                                    </h3>
                                                    <p style={{ textAlign: "center",fontSize:20 }}>Não foram localizadas encomendas para<br /> Nota Fiscal <b>{minuta}</b>.</p>
                                                    <button style={{ marginTop: 20, width: '100%' }} onClick={() => limpaBusca()} type="button"
                                                        className="inline-flex items-center justify-center rounded bg-primary py-4 px-2 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-dark">
                                                        Novo Rastreio
                                                    </button>

                                                </>
                                                :
                                                <div className="mx-auto mb-[60px] max-w-[620px] text-center lg:mb-20">
                                                    <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[42px]">
                                                        Insira o seu CNPJ e o código da Nota Fiscal
                                                    </h2>
                                                    <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                                                        Para localizar seu rastreio, verifique a seu CNPJ, sua Nota Fiscal e em seguida, clique em “Rastrear”
                                                    </p>
                                                    <form >
                                                        <div className="mb-2 col-md-6">
                                                            <input type="text" value={cpfCnpj} onChange={handleCpfCnpj} name="fullName" placeholder="CPF / CNPJ"
                                                                className="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none" />
                                                        </div>
                                                        <p style={{ color: 'red', textAlign: 'start', fontSize: 12 }}>{erroForm === 1 ? "Favor digite um CNPJ válido" : null}</p>
                                                        <div className="mb-2">
                                                            <input type="text" value={minuta} onChange={handleMinuta} name="fullName" placeholder="Nota Fiscal"
                                                                className="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none" />
                                                        </div>
                                                        <p style={{ color: 'red', textAlign: 'start', fontSize: 12 }}>{erroForm === 2 ? "Favor digite a Nota Fiscal de 6 dígitos" : null}</p>
                                                        <div className="mb-0">
                                                            <button onClick={() => buscaPedido()} type="button"
                                                                className="inline-flex items-center justify-center rounded bg-primary py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-dark">
                                                                Rastrear
                                                            </button>
                                                            {/* <button style={{ marginLeft: 10 }} onClick={() => limpaBusca()} type="button"
                                                            className="inline-flex items-center justify-center rounded bg-primary py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-dark">
                                                            Mudar Tipo
                                                        </button> */}
                                                        </div>
                                                    </form>
                                                </div>
                                        }
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="blog" className="relative z-20 overflow-hidden bg-[#f3f4ff] pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4">
                            <div className="mx-auto mb-[60px] max-w-[620px] text-center lg:mb-20">
                                <span className="mb-2 block text-lg font-semibold text-primary">
                                    Mantenha-se atualizado sobre o universo de logística
                                </span>
                                <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[42px]">
                                    Acompanhe nosso blog e confira nossos artigos exclusivos
                                </h2>
                                <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                                    O melhor conteúdo por quem entende do assunto. Não deixe de ficar por dentro dos nossos conteúdos.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="-mx-4 flex flex-wrap">
                        {blog.slice(0, 3).map((b) =>
                            <div key={b.id} className="w-full px-4 md:w-1/2 lg:w-1/3">
                                <div className="wow fadeInUp group mb-10" data-wow-delay=".1s">
                                    <div style={{ height: 260 }} className="mb-8 overflow-hidden rounded">
                                        <a href={"/post?d=" + b.slug} className="block">
                                            <img src={b.miniatura} alt="image"
                                                className="w-full transition group-hover:rotate-6 group-hover:scale-125" />
                                        </a>
                                    </div>
                                    <div>
                                        <span className="mb-5 inline-block rounded bg-primary py-1 px-4 text-center text-xs font-semibold leading-loose text-white">
                                            {moment(b.dataCadastro).format('ll')}
                                        </span>
                                        <h3 style={{ height: 75 }}>
                                            <a href={"/post?d=" + b.slug}
                                                className="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary sm:text-2xl lg:text-xl xl:text-2xl">
                                                {b.titulo.substr(0, 50)}...
                                            </a>
                                        </h3>
                                        <p className="text-base text-body-color">
                                            {b.descricao.substr(0, 90)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div style={{ alignItems: "center" }}>
                            <a href="/blog" type="button"
                                className="inline-flex items-center justify-center rounded bg-primary py-4 px-10 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-dark">
                                Ver todos os Posts
                            </a>
                        </div>
                    </div>
                </div>

                <div className="absolute bottom-0 right-0 z-[-1]">
                    <svg width="1440" height="886" viewBox="0 0 1440 886" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5"
                            d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
                            fill="url(#paint0_linear)" />
                        <defs>
                            <linearGradient id="paint0_linear" x1="1308.65" y1="1142.58" x2="602.827" y2="-418.681"
                                gradientUnits="userSpaceOnUse">
                                <stop stopColor="#3056D3" stopOpacity="0.36" />
                                <stop offset="1" stopColor="#F5F2FD" stopOpacity="0" />
                                <stop offset="1" stopColor="#F5F2FD" stopOpacity="0.096144" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </section>
            {/* <div style={{ textAlign: 'center' }}>
                <Adsense
                    className='blockHome2'
                    client='ca-pub-9383816332258420'
                    slot='8682041194'
                    style={{ display: 'block' }}
                    layout="in-article"
                    format="fluid"
                    responsive={true}
                />
            </div> */}

            {/* <section id="contato" className="relative py-20 md:py-[120px]">
                <div className="absolute top-0 left-0 z-[-1] h-1/2 w-full bg-[#f3f4fe] lg:h-[45%] xl:h-1/2"></div>
                <div className="container px-4">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4 lg:w-7/12 xl:w-8/12">
                            <div className="ud-contact-content-wrapper">
                                <div className="ud-contact-title mb-12 lg:mb-[150px]">
                                    <span className="mb-5 text-base font-semibold text-dark">
                                        Fale conosco
                                    </span>
                                    <h2 className="text-[35px] font-semibold">
                                        Teremos o maior prazer <br />
                                        em falar com você.
                                    </h2>
                                </div>
                                <div className="mb-12 flex flex-wrap justify-between lg:mb-0">
                                    <div className="mb-8 flex w-[330px] max-w-full">
                                        <div className="mr-6 text-[32px] text-primary">
                                            <svg width="29" height="35" viewBox="0 0 29 35" className="fill-current">
                                                <path
                                                    d="M14.5 0.710938C6.89844 0.710938 0.664062 6.72656 0.664062 14.0547C0.664062 19.9062 9.03125 29.5859 12.6406 33.5234C13.1328 34.0703 13.7891 34.3437 14.5 34.3437C15.2109 34.3437 15.8672 34.0703 16.3594 33.5234C19.9688 29.6406 28.3359 19.9062 28.3359 14.0547C28.3359 6.67188 22.1016 0.710938 14.5 0.710938ZM14.9375 32.2109C14.6641 32.4844 14.2812 32.4844 14.0625 32.2109C11.3828 29.3125 2.57812 19.3594 2.57812 14.0547C2.57812 7.71094 7.9375 2.625 14.5 2.625C21.0625 2.625 26.4219 7.76562 26.4219 14.0547C26.4219 19.3594 17.6172 29.2578 14.9375 32.2109Z" />
                                                <path
                                                    d="M14.5 8.58594C11.2734 8.58594 8.59375 11.2109 8.59375 14.4922C8.59375 17.7188 11.2187 20.3984 14.5 20.3984C17.7812 20.3984 20.4062 17.7734 20.4062 14.4922C20.4062 11.2109 17.7266 8.58594 14.5 8.58594ZM14.5 18.4297C12.3125 18.4297 10.5078 16.625 10.5078 14.4375C10.5078 12.25 12.3125 10.4453 14.5 10.4453C16.6875 10.4453 18.4922 12.25 18.4922 14.4375C18.4922 16.625 16.6875 18.4297 14.5 18.4297Z" />
                                            </svg>
                                        </div>
                                        <div>
                                            <h5 className="mb-6 text-lg font-semibold">Matriz</h5>
                                            <p className="text-base text-body-color">
                                                Av. Santos Dumont, 66 - Anil. São Luís - MA - 65.046-660
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mb-8 flex w-[330px] max-w-full">
                                        <div className="mr-6 text-[32px] text-primary">
                                            <svg width="34" height="25" viewBox="0 0 34 25" className="fill-current">
                                                <path
                                                    d="M30.5156 0.960938H3.17188C1.42188 0.960938 0 2.38281 0 4.13281V20.9219C0 22.6719 1.42188 24.0938 3.17188 24.0938H30.5156C32.2656 24.0938 33.6875 22.6719 33.6875 20.9219V4.13281C33.6875 2.38281 32.2656 0.960938 30.5156 0.960938ZM30.5156 2.875C30.7891 2.875 31.0078 2.92969 31.2266 3.09375L17.6094 11.3516C17.1172 11.625 16.5703 11.625 16.0781 11.3516L2.46094 3.09375C2.67969 2.98438 2.89844 2.875 3.17188 2.875H30.5156ZM30.5156 22.125H3.17188C2.51562 22.125 1.91406 21.5781 1.91406 20.8672V5.00781L15.0391 12.9922C15.5859 13.3203 16.1875 13.4844 16.7891 13.4844C17.3906 13.4844 17.9922 13.3203 18.5391 12.9922L31.6641 5.00781V20.8672C31.7734 21.5781 31.1719 22.125 30.5156 22.125Z" />
                                            </svg>
                                        </div>
                                        <div>
                                            <h5 className="mb-6 text-lg font-semibold">Envie-nos um e-mail</h5>
                                            <p className="text-base text-body-color">comercial@entregaja.com.br</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {sucessoEmail ?
                            <div className="w-full px-4 lg:w-5/12 xl:w-4/12">
                                <div
                                    className="wow fadeInUp rounded-lg bg-white py-10 px-8 shadow-testimonial sm:py-12 sm:px-10 md:p-[60px] lg:p-10 lg:py-12 lg:px-10 2xl:p-[60px]"
                                    data-wow-delay=".2s
          ">
                                    <h3 className="mb-8 text-2xl font-semibold md:text-[26px]">
                                        Vamos agendar uma Reunião?
                                    </h3>
                                    <p>Agora é só aguardar nosso comercial entrar em contato!!</p>
                                    <form>
                                        <div className="mb-0">
                                            <a href="/sobre" type="button"
                                                className="inline-flex items-center justify-center rounded bg-primary py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-dark">
                                                Conhecer a Entrega Já
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            :
                            <div className="w-full px-4 lg:w-5/12 xl:w-4/12">
                                <div
                                    className="wow fadeInUp rounded-lg bg-white py-10 px-8 shadow-testimonial sm:py-12 sm:px-10 md:p-[60px] lg:p-10 lg:py-12 lg:px-10 2xl:p-[60px]"
                                    data-wow-delay=".2s
              ">
                                    <h3 className="mb-8 text-2xl font-semibold md:text-[26px]">
                                        Que tal marcar uma Reunião?
                                    </h3>
                                    <form>
                                        <div className="mb-6">
                                            <label htmlFor="text" className="block text-xs text-dark">Empresa*</label>
                                            <input value={empresa} onChange={handleEmpresa} type="text" name="email" placeholder="Nome da Empresa"
                                                className="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none" />
                                            {erroForm === 1 ?
                                                <p style={{ color: "red", fontSize: 10 }}>Favor digite o nome da Empresa</p>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="mb-6">
                                            <label className="block text-xs text-dark">Nome do Representante *</label>
                                            <input value={representante} onChange={handleRepresentante} type="text" placeholder="Nome Completo"
                                                className="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none" />
                                            {erroForm === 2 ?
                                                <p style={{ color: "red", fontSize: 10 }}>Favor digite o nome do representante</p>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="email" className="block text-xs text-dark">Email*</label>
                                            <input value={email} onChange={handleEmail} type="text" name="email" placeholder="example@yourmail.com"
                                                className="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none" />
                                            {erroForm === 3 ?
                                                <p style={{ color: "red", fontSize: 10 }}>Favor digite o email para envio</p>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="phone" className="block text-xs text-dark">Whatsapp*</label>
                                            <input value={whatsapp} onChange={handleWhatsapp} type="text" name="phone" placeholder="+55 (DD) 99999-9999"
                                                className="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none" />
                                            {erroForm === 4 ?
                                                <p style={{ color: "red", fontSize: 10 }}>Favor digite o número de whatsapp adicionando o 9</p>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="message" className="block text-xs text-dark">Mensagem*</label>
                                            <textarea value={mensagem} onChange={handleMensagem} name="message" rows="1" placeholder="Deixe sua mensagem para nós"
                                                className="w-full resize-none border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none"></textarea>
                                            {erroForm === 5 ?
                                                <p style={{ color: "red", fontSize: 10 }}>Digite uma breve mensagem sobre seu negócio</p>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="mb-0">

                                            {loadingEmail ?
                                                <button style={{ backgroundColor: "grey" }} onClick={() => enviaEmail()} type="button"
                                                    className="inline-flex items-center justify-center rounded bg-defafult py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-dark">
                                                    Enviando solicitação...
                                                </button>
                                                :
                                                <button onClick={() => enviaEmail()} type="button"
                                                    className="inline-flex items-center justify-center rounded bg-primary py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-dark">
                                                    Solicitar Reunião
                                                </button>
                                            }

                                        </div>
                                    </form>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </section> */}


            <Footer />
        </>
    );
}
