import React, { useEffect } from 'react';
import Routes from './Router'
import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyCaprFZQ0HlRmXUGQZYzSeA1E1dWWAICMM",
  authDomain: "entrega-ja-a942a.firebaseapp.com",
  projectId: "entrega-ja-a942a",
  storageBucket: "entrega-ja-a942a.appspot.com",
  messagingSenderId: "974455797319",
  appId: "1:974455797319:web:e03695c96da07d92c92362",
  measurementId: "G-X5RSVBKZMT"
};
firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
