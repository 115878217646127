import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Adsense } from '@ctrl/react-adsense';

export default function Servicos() {
    return (
        <>
            <Header />
            <div className="relative z-10 overflow-hidden bg-primary pt-[120px] pb-[100px] md:pt-[130px] lg:pt-[160px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4">
                            <div className="text-center">
                                <h1 className="text-4xl font-semibold text-white">Equipe especializada +<br /> Excelência Logística +<br /> Tecnologias Inovadoras = Prazer, somos a Entrega Já!</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <Adsense
                    className='blockServicos'
                    client='ca-pub-9383816332258420'
                    slot='8682041194'
                    style={{ display: 'block' }}
                    layout="in-article"
                    format="fluid"
                    responsive={true}
                />
            </div>
            <section id="pricing" className="relative z-20 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]">
                <div className="container">

                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4">
                            <div className="mx-auto mb-[60px] max-w-[620px] text-center lg:mb-20">
                                <span className="mb-2 block text-lg font-semibold text-primary">
                                    Soluções para o tamanho do seu negócio
                                </span>
                                <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]">
                                    Conheça nossas soluções logísticas
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap items-center justify-center">
                        <div className="w-full md:w-1/2 lg:w-1/3">
                            <div
                                className="wow fadeInUp relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 text-center shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                                data-wow-delay=".15s
              ">
                                <span className="mb-2 block text-base font-medium uppercase text-dark">
                                    TRANSFERÊNCIA
                                    DE CARGAS
                                </span>

                                <div className="mb-10">
                                    <p className="mb-1 text-base font-medium leading-loose text-body-color">
                                        Estamos crescendo cada vez mais no serviço de transferência de cargas, dessa forma realizamos o transporte de sua mercadoria com exclusividade por meio das melhores frotas e conforme a necessidade de cada operação, em todo território nacional.
                                    </p>
                                </div>
                                <span className="absolute left-0 bottom-0 z-[-1] block h-14 w-14 rounded-tr-full bg-primary">
                                </span>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3">
                            <div
                                className="wow fadeInUp relative z-10 mb-10 overflow-hidden rounded-xl bg-primary bg-gradient-to-b from-primary to-[#179BEE] py-10 px-8 text-center shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                                data-wow-delay=".1s
              ">
                                <span
                                    className="mb-5 inline-block rounded-full border border-white bg-white py-2 px-6 text-base font-semibold uppercase text-primary">
                                    CARGAS
                                    FRACIONADAS
                                </span>

                                <div className="mb-10">
                                    <p className="mb-1 text-base font-medium leading-loose text-white">
                                        Nesse tipo de serviço são transportados diferentes produtos, em pequenas quantidades e que não ocupam a capacidade total do veículo. Com um sistema inteligente de gestão de fretes, que organiza as rotas de forma segura e econômica, facilitando a entrega de pequenos materiais.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3">
                            <div
                                className="wow fadeInUp relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 text-center shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                                data-wow-delay=".15s
              ">
                                <span className="mb-2 block text-base font-medium uppercase text-dark">
                                    ARMAZENAGEM
                                </span>

                                <div className="mb-10">
                                    <p className="mb-1 text-base font-medium leading-loose text-body-color">
                                        Em uma localização estratégica, a Entrega Já disponibiliza de um ótimo serviço de armazenagem de mercadorias diversas. Com uma infraestrutura completa, possibilitando a integração do processo de movimentação das cargas entre as cadeias logísticas.
                                    </p>
                                </div>

                                <span className="absolute right-0 top-0 z-[-1] block h-14 w-14 rounded-bl-full bg-secondary">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-center">
                        <div className="w-full md:w-1/2 lg:w-1/3">
                            <div
                                className="wow fadeInUp relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 text-center shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                                data-wow-delay=".15s
              ">
                                <span className="mb-2 block text-base font-medium uppercase text-dark">
                                    OPERAÇÕES
                                    DEDICADAS
                                </span>

                                <div className="mb-10">
                                    <p className="mb-1 text-base font-medium leading-loose text-body-color">
                                        Operação de transporte customizada. São desenhadas e planejadas sob medida, à necessidade de cada cliente. Utiliza-se de uma malha de transporte total ou parcialmente dedicada, com a gestão e os nossos padrões de qualidade.
                                    </p>
                                </div>
                                <span className="absolute left-0 bottom-0 z-[-1] block h-14 w-14 rounded-tr-full bg-primary">
                                </span>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3">
                            <div
                                className="wow fadeInUp relative z-10 mb-10 overflow-hidden rounded-xl bg-primary bg-gradient-to-b from-primary to-[#179BEE] py-10 px-8 text-center shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                                data-wow-delay=".1s
              ">
                                <span
                                    className="mb-5 inline-block rounded-full border border-white bg-white py-2 px-6 text-base font-semibold uppercase text-primary">
                                    LOGISTICA
                                    REVERSA
                                </span>

                                <div className="mb-10">
                                    <p className="mb-1 text-base font-medium leading-loose text-white">
                                        Distribuição fracionada porta a porta Reversa, com origem na casa do consumidor e destino no CD do Contratante. Utiliza-se do nosso app que conecta os três elos da operação: o cliente final, o cliente embarcador e o motorista.
                                    </p>
                                    <p className="mb-1 text-base font-medium leading-loose text-white">
                                        Nossas coletas são previamente agendadas e utilizam um checklist eletrônico que instrui e orienta os nossos motoristas para a realização da coleta. .
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3">
                            <div
                                className="wow fadeInUp relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 text-center shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                                data-wow-delay=".15s
              ">
                                <span className="mb-2 block text-base font-medium uppercase text-dark">
                                    FIRST
                                    MILE (Inbound)
                                </span>

                                <div className="mb-10">
                                    <p className="mb-1 text-base font-medium leading-loose text-body-color">
                                        Operação de coletas fracionadas tipo “milk run” tendo como destinatário o próprio CD do cliente contratante. Também conhecida como abastecimento de estoque ou a primeira “perna” de operações de Cross Docking. É a possibilidade de conectar os diversos Sellers às operações de Fulfillment dos Marketplaces.
                                    </p>
                                </div>

                                <span className="absolute right-0 top-0 z-[-1] block h-14 w-14 rounded-bl-full bg-secondary">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />
        </>
    );
}
