import React, { Component, useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import ReactGa from 'react-ga'

// Site
import Home from './pages/Home'

import Sobre from './pages/Sobre'
import Filial from './pages/Sobre/Filial'

import Servicos from './pages/Servicos'
import Parceiros from './pages/Parceiros'
import Oportunindades from './pages/Parceiros/Oportunidades'
import Contato from './pages/Contato'
import Atendimento from './pages/Atendimento'
import Cotacao from './pages/Cotacao'

// Blog
import Post from './pages/Blog/Post'
import Blog from './pages/Blog/Lista'
import NotFoundPage from './pages/NotFound';

const TRACKING = 'G-F6C7WZNPG1'

ReactGa.initialize(TRACKING)

export default function Rotas() {

    useEffect(() => {
        ReactGa.pageview(window.location.pathname + window.location.search)
    }, [])
    return (
        <div className="App">
            <BrowserRouter>
                {/* Site */}
                <Route path="/" exact={true} component={Home} />

                <Route path="/sobre" exact={true} component={Sobre} />
                <Route path="/filial" exact={true} component={Filial} />

                <Route path="/servicos" exact={true} component={Servicos} />
                <Route path="/parceiros" exact={true} component={Parceiros} />
                <Route path="/oportunidades" exact={true} component={Oportunindades} />
                <Route path="/contato" exact={true} component={Contato} />
                <Route path="/cotacao" exact={true} component={Cotacao} />
                <Route path="/atendimento" exact={true} component={Atendimento} />

                {/* Blog */}
                <Route path="/post" exact={true} component={Post} />
                <Route path="/blog" exact={true} component={Blog} />
                {/* <Route component={NotFoundPage} /> */}
            </BrowserRouter>
        </div>
    );
}