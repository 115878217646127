import React, { useState, useEffect } from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

export default function Oportunidades() {
    return (
        <>
            <Header />
            <div className="relative z-10 overflow-hidden bg-primary pt-[120px] pb-[100px] md:pt-[130px] lg:pt-[160px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4">
                            <div className="text-center">
                                <h1 className="text-4xl font-semibold text-white">Venha fazer parte do nosso time</h1>
                                <h1 className="text-4xl font-semibold text-white">Envio de currículo, vagas disponiveis e muito mais..</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="about" className="bg-[#f3f4fe] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]">
                <div className="container">
                    <div className="wow fadeInUp bg-white" data-wow-delay=".2s">
                        <div className="-mx-4 flex flex-wrap">
                            <div className="w-full px-4">
                                <div className="items-center justify-between overflow-hidden border lg:flex">
                                    <div
                                        className="w-full py-12 px-7 sm:px-12 md:p-16 lg:max-w-[565px] lg:py-9 lg:px-16 xl:max-w-[640px] xl:p-[70px]">
                                        <span className="mb-5 inline-block bg-primary py-2 px-5 text-sm font-medium text-white">
                                            Estamos quase lá...
                                        </span>
                                        <h2 className="mb-6 text-3xl font-bold text-dark sm:text-4xl sm:leading-snug 2xl:text-[40px]">
                                            Estamos preparando o melhor para você
                                        </h2>
                                        <p className="mb-9 text-base leading-relaxed text-body-color">
                                            Nosso sistema está em construção, breve mostraremos nossas vagas aqui. Mas não se preocupe, você pode nos enviar seu currículo para o email
                                        </p>
                                        <h2 className="mb-6 text-3xl font-bold text-dark sm:text-4xl sm:leading-snug 2xl:text-[40px]">
                                            fiscal@entregaja.com.br
                                        </h2>
                                    </div>
                                    <div >
                                        <div className="relative z-10 inline-block">
                                            <img src="assets/images/construcao.jpg" alt="image" className="mx-auto lg:ml-auto" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    );
}
