import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';


// CSS
// import './bootstrap.min.css'
// import './style.css'


// import { Container } from 'native-base-web';

export default function Cotacao() {
    return (
        <>
            {/* <Header /> */}
            {/* <div className="relative z-10 overflow-hidden bg-primary pt-[120px] pb-[100px] md:pt-[130px] lg:pt-[160px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4">
                            <div className="text-center">
                                <h1 className="text-4xl font-semibold text-white">Realize uma Cotação</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div style={{ padding: 20 }}>
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 mr-auto">
                            <h3 class="mb-3">Let's work together</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae sequi, ipsa hic alias officia facilis
                                eveniet, neque laborum cumque maxime soluta. Neque atque necessitatibus ipsam sequi soluta magni, iste vero
                                fuga inventore, explicabo totam quis quia nemo possimus cupiditate doloribus?</p>
                            <p>Quasi optio numquam pariatur amet laudantium, dicta ullam obcaecati nihil eveniet et aperiam beatae illum
                                vitae in asperiores, a ex. Voluptates accusantium, beatae dolorem natus delectus nostrum, iusto nemo optio
                                quas eum, quis a in reprehenderit totam, sequi distinctio impedit.</p>
                            <p>Dolor aliquid, commodi vitae dolorum veniam amet error! Nemo nam quibusdam delectus dolore eveniet officia
                                harum eum sit magnam possimus. Eum optio dolores repellat ea impedit, in unde, rem ipsam rerum autem iusto
                                vero facilis blanditiis explicabo sapiente consequatur provident? <a href="#">info@mywebsite.com</a></p>

                        </div>

                        <div class="col-md-6">
                            <div class="box">
                                <h3 class="heading">How Can We Help?</h3>
                                <form class="mb-5" method="post" id="contactForm" name="contactForm">
                                    <div class="row">

                                        <div class="col-md-6 form-group">
                                            <label for="name" class="col-form-label">Name *</label>
                                            <input type="text" class="form-control" name="name" id="name" placeholder="Your name" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label for="name" class="col-form-label">Organization</label>
                                            <input type="text" class="form-control" name="organization" id="organization"
                                                placeholder="Your organization" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 form-group">
                                            <label for="email" class="col-form-label">Email *</label>
                                            <input type="text" class="form-control" name="email" id="email" placeholder="Your email address" />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 form-group">
                                            <label for="message" class="col-form-label">Project Description *</label>
                                            <textarea class="form-control" name="message" id="message" cols="30" rows="7"></textarea>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 form-group">
                                            <label for="budget" class="col-form-label">Budget</label>
                                            <select class="custom-select" id="budget" name="budget">
                                                <option selected>Choose...</option>
                                                <option value="$1000 below"> $1,000</option>
                                                <option value="$2,000 - $5,000">$2,000 - $5,000</option>
                                                <option value="$5,000 - $15,000">$5,000 - $15,000</option>
                                                <option value="$15,000 - $25,000">$15,000 - $25,000</option>
                                                <option value="$25,000 >">$25,000 </option>
                                            </select>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label for="message" class="col-form-label">Target Lunch</label>
                                            <input type="text" class="form-control" name="date" id="date" placeholder="Example: January 15th" />


                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <input type="submit" value="Send Message" class="btn btn-block btn-primary rounded-0 py-2 px-4" />
                                            <span class="submitting"></span>
                                        </div>
                                    </div>
                                </form>

                                <div id="form-message-warning mt-4"></div>
                                <div id="form-message-success">
                                    Your message was sent, thank you!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <iframe src="https://administrador.entregaja.com.br/cotacao" style={{ height: 900, width: '100%' }} title="Iframe Example">

            </iframe> */}

            <Footer />
        </>
    );
}
